import { CookieConsentConfig } from "vanilla-cookieconsent";

import { initGA, optOutGA } from "./GoogleAnalytics";
const getConfig = () => {
  const config = {
    // root: 'body',
    // autoShow: true,
    // disablePageInteraction: true,
    // hideFromBots: true,
    // mode: 'opt-in',
    // revision: 0,

    cookie: {
      // name: 'cc_cookie',
      // domain: location.hostname,
      // path: '/',
      // sameSite: "Lax",
      // expiresAfterDays: 365,
    },

    /**
     * Callback functions
     */
    // onFirstConsent: ({ cookie }) => {
    //   console.log('onFirstConsent fired', cookie);
    // },

    // onConsent: ({ cookie }) => {
    //   console.log('onConsent fired!', cookie);
    // },

    // onChange: ({ changedCategories, changedServices }) => {
    //   console.log('onChange fired!', changedCategories, changedServices);
    // },

    // onModalReady: ({ modalName }) => {
    //   console.log('ready:', modalName);
    // },

    // onModalShow: ({ modalName }) => {
    //   console.log('visible:', modalName);
    // },

    // onModalHide: ({ modalName }) => {
    //   console.log('hidden:', modalName);
    // },

    // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
    guiOptions: {
      consentModal: {
        layout: "box inline",
        position: "bottom right",
        equalWeightButtons: true,
        flipButtons: false,
      },
      preferencesModal: {
        layout: "box",
        equalWeightButtons: true,
        flipButtons: false,
      },
    },

    categories: {
      necessary: {
        enabled: true, // this category is enabled by default
        readOnly: true, // this category cannot be disabled
      },
      analytics: {
        autoClear: {
          cookies: [
            {
              name: /^_ga/, // regex: match all cookies starting with '_ga'
            },
            {
              name: "_gid", // string: exact cookie name
            },
          ],
        },

        // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
        services: {
          ga: {
            label: "Google Analytics",
            onAccept: () => {
              initGA();
            },
            onReject: () => {
              optOutGA();
            },
          },
          // youtube: {
          //   label: "Youtube Embed",
          //   onAccept: () => {},
          //   onReject: () => {},
          // },
        },
      },
      ads: {},
    },

    language: {
      default: "de",
      autoDetect: "document",
      translations: {
        de: {
          consentModal: {
            title: false,
            description:
              "RAY verwendet essentielle Cookies, die für das ordnungsgemäße Funktionieren der Website unbedingt erforderlich sind. Erfahren Sie mehr über unsere Cookie- Richtlinien.",
            acceptAllBtn: "Akzeptieren",
            acceptNecessaryBtn: "Ablehnen",
            showPreferencesBtn: "Einstellungen öffnen",
            // closeIconLabel: 'Reject all and close modal',
            footer: false,
            // footer: `
            //             <a href="#path-to-impressum.html" target="_blank">Impressum</a>
            //             <a href="#path-to-privacy-policy.html" target="_blank">Privacy Policy</a>
            //         `,
          },
          preferencesModal: {
            title: "Dienste, die wir nutzen möchten",
            acceptAllBtn: "Alle akzeptieren",
            acceptNecessaryBtn: "Ablehnen",
            savePreferencesBtn: "Ausgewählte akzeptieren",
            closeIconLabel: "Schließen",
            serviceCounterLabel: "Service|Services",
            sections: [
              {
                title: "Einstellungen",
                description:
                  "Hier können Sie die Dienste, die wir auf dieser Website nutzen möchten, bewerten und anpassen. Sie haben das Sagen! Aktivieren oder deaktivieren Sie die Dienste, wie Sie es für richtig halten. Um mehr zu erfahren, lesen Sie bitte unsere Datenschutzerklärung.",
              },
              {
                title: "Dienstbereitstellung",
                description:
                  "Diese Dienste sind für die korrekte Funktion dieser Website unerlässlich. Sie können sie hier nicht deaktivieren, da der Dienst sonst nicht richtig funktionieren würde.",

                //this field will generate a toggle linked to the 'necessary' category
                linkedCategory: "necessary",
              },
              {
                title: "Analytics",
                description:
                  "Wir verwenden Performance- und Analyse-Cookies, um zu verstehen, wie Sie die Website nutzen, z.B. welche Seiten und Videos Sie sich am häufigsten ansehen, um auftretende Fehler zu messen und verschiedene Designideen zu testen.",
                linkedCategory: "analytics",
              },
              // {
              //   title: "More information",
              //   description:
              //     'For any queries in relation to my policy on cookies and your choices, please <a href="#contact-page">contact us</a>',
              // },
            ],
          },
        },
        en: {
          consentModal: {
            title: false,
            description:
              "RAY uses cookies, which are essential for the proper functioning of the website. Learn more about our cookie policy.",
            acceptAllBtn: "Accept",
            acceptNecessaryBtn: "Reject",
            showPreferencesBtn: "Settings",
            // closeIconLabel: 'Reject all and close modal',
            footer: false,
            // footer: `
            //             <a href="#path-to-impressum.html" target="_blank">Impressum</a>
            //             <a href="#path-to-privacy-policy.html" target="_blank">Privacy Policy</a>
            //         `,
          },
          preferencesModal: {
            title: "Services we would like to use",
            acceptAllBtn: "Accept all",
            acceptNecessaryBtn: "Reject",
            savePreferencesBtn: "Accept selected",
            closeIconLabel: "Close",
            serviceCounterLabel: "Service|Services",
            sections: [
              {
                title: "Settings",
                description:
                  "Here you can rate and customize the services we want to use on this website. You have the say! Activate or deactivate the services as you see fit. To find out more, please read our privacy policy.",
              },
              {
                title: "Services",
                description:
                  "These services are essential for the correct functioning of this website. You cannot deactivate them here, otherwise the service would not work properly.",

                //this field will generate a toggle linked to the 'necessary' category
                linkedCategory: "necessary",
              },
              {
                title: "Analytics",
                description:
                  "We use performance and analytics cookies to understand how you use the website, e.g. which pages and videos you view most often, to measure errors that occur and to test different design ideas.",
                linkedCategory: "analytics",
              },
              // {
              //   title: "More information",
              //   description:
              //     'For any queries in relation to my policy on cookies and your choices, please <a href="#contact-page">contact us</a>',
              // },
            ],
          },
        },
      },
    },
  };

  return config;
};

export default getConfig;
