import React from "react";
export const RayLogo = () => (
  <svg
    width="105"
    height="105"
    viewBox="0 0 105 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="52.5" cy="52.5" r="52.5" fill="var(--color-primary)" />
    <path
      d="M13.9101 69.8555V38.7126H26.9901C33.886 38.7126 37.7122 42.0938 37.7122 47.0767C37.7122 51.8816 33.7081 55.5298 27.0791 55.5742L38.0681 69.8555H32.9518L22.4522 55.6632H18.0032V69.8555H13.9101ZM18.0032 52.46H26.3673C31.1277 52.46 33.5301 50.28 33.5301 47.0767C33.5301 43.8734 30.9052 42.0049 26.8567 42.0049H18.0032V52.46ZM40.7681 69.8555L53.4921 38.7126H57.3183L69.9534 69.8555H65.5489L62.1232 60.8685H48.5538L45.0391 69.8555H40.7681ZM49.844 57.6653H60.8774L55.3607 43.384L49.844 57.6653ZM79.4971 69.8555V55.8857L67.8408 38.7126H72.5122L81.6326 52.371L90.9755 38.7126H95.38L83.5902 55.9302V69.8555H79.4971Z"
      fill="var(--color-secondary)"
    />
  </svg>
);
