import Link from "next/link";
import { useRouter } from "next/router";

import NavFooter from "@/components/global/NavFooter";
import { RayLogo } from "@/components/icons/RayLogo";
import useHasMounted from "@/hooks/useHasMounted";

const Footer = ({ footer, contact, loading }) => {
  const { address, email, phone } = contact;

  const hasMounted = useHasMounted();
  const { locale } = useRouter();

  return !hasMounted ? null : (
    <>
      <footer className="Footer" data-loading={loading}>
        <div className="wrapper">
          <div className="contact">
            {address?.[locale]}
            <br />
            <br />M <a href={`mailto:${email}`}>{email}</a>
            <br />T <a href={`tel:${phone}`}>{phone}</a>
          </div>
          <NavFooter footer={footer} />
        </div>
      </footer>

      <Link className="logo" href="/" scroll={false}>
        <RayLogo />
      </Link>

      <style jsx global>{`
        .Footer {
          padding: var(--dist-1);
          font-size: var(--fs-xs);
          line-height: var(--lh-xs);
          background: var(--color-secondary);
          font-family: "ABCROMMono-Regular";
          margin-top: calc(var(--dist-5) + var(--dist-7) - var(--dist-1));
        }

        .Footer .Hyperlink:not(.Button) {
        }
        @media (hover: hover) {
          .Footer .Hyperlink:not(.Button):hover {
            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-underline-offset: 1.5px;
          }
          .Footer a:hover {
            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-underline-offset: 1.5px;
          }
        }

        .Footer .wrapper {
          position: relative;
          border: 1px solid var(--color-primary);
        }

        .Footer .contact {
          white-space: pre-line;
        }

        .Footer + .logo {
          position: sticky;
          bottom: var(--dist-1);
          left: var(--dist-1);
          width: var(--global-column);
          aspect-ratio: 1;
          text-align: center;
          margin-top: var(--dist-1);
          display: flex;
          z-index: 999;
        }

        .Footer + .logo > * {
          width: 100%;
          height: auto;
          aspect-ratio: 1;
        }

        .Footer[data-loading="true"] + .logo {
          animation: spin 0.66s ease-in-out infinite;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        @media (max-width: 1023px) {
          .Footer .contact {
            padding: var(--dist-1);
          }
        }

        @media (min-width: 1024px) {
          .Footer .wrapper {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            gap: var(--dist-1);
          }

          .Footer .contact {
            padding: var(--dist-1);
            grid-column: 1/4;
          }

          .Footer .NavFooter {
            grid-column: 4/7;
          }

          .Footer .NavFooter > ul {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: var(--dist-1);
          }

          .Footer .NavFooter > ul > li:nth-child(3n + 0) {
            padding-right: var(--dist-1);
          }
        }
      `}</style>
    </>
  );
};

export default Footer;
