import { motion } from "framer-motion";
import { useRouter } from "next/router";
import { useMediaQuery } from "usehooks-ts";

import Accordion, {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/shared/Accordion";
import Hyperlink from "@/components/shared/Hyperlink";
import ConditionalWrapper from "@/components/utils/ConditionalWrapper";
import useHasMounted from "@/hooks/useHasMounted";

const Nav = ({ nav }) => {
  const { menu } = nav || {};

  const hasMounted = useHasMounted();
  const { locale } = useRouter();
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  return !hasMounted ? null : (
    <>
      <nav className="Nav">
        <motion.ul>
          {menu?.map(({ _key, title, items }) => (
            <li key={_key}>
              <ConditionalWrapper
                condition={!isDesktop}
                wrapper={(children) => (
                  <Accordion>
                    <AccordionItem value={_key}>
                      <AccordionTrigger className="section">
                        {title?.[locale]}
                      </AccordionTrigger>
                      <AccordionContent>{children}</AccordionContent>
                    </AccordionItem>
                  </Accordion>
                )}
              >
                {items && (
                  <ul className="items">
                    {items?.map(
                      ({ _key, ...link }) =>
                        (!link.reference?._translations?.length > 0 ||
                          !!link.reference?._translations?.filter(
                            (translation) => translation?.language === locale,
                          )?.[0]) && (
                          <li key={_key}>
                            <Hyperlink
                              link={{
                                ...link,
                                reference: {
                                  ...link.reference,
                                  ...link.reference?._translations?.filter(
                                    (translation) =>
                                      translation?.language === locale,
                                  )?.[0],
                                },
                              }}
                            />
                          </li>
                        ),
                    )}
                  </ul>
                )}
              </ConditionalWrapper>
            </li>
          ))}
        </motion.ul>
      </nav>

      <style jsx global>{`
        .Nav {
        }

        .Nav .section {
          text-transform: uppercase;
        }

        .Nav > ul {
          padding: var(--dist-1);
          gap: var(--global-gutter);
        }

        @media (hover: hover) {
          .Nav a:hover {
            text-decoration: underline;
          }
        }

        @media (max-width: 1023px) {
          .Nav .section {
            font-size: var(--fs-lg);
            line-height: var(--lh-lg);
            letter-spacing: var(--ls-lg);
            font-family: "ABCROM-Regular";
          }

          .Nav ul.items {
            padding: var(--dist-2) 0;
          }

          .Nav > ul {
            margin-bottom: calc(
              var(--dist-13) - 2 * var(--dist-1)
            ); // Compensate for own and sibling padding
          }
        }

        @media (min-width: 1024px) {
          .Nav > ul {
            padding: var(--dist-1) 0;
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            gap: var(--global-gutter);
          }

          .Nav > ul li:first-child > .items {
            padding-left: var(--dist-1);
          }

          .Nav > ul li:last-child > .items {
            padding-right: var(--dist-1);
          }

          .Nav .items {
          }
        }

        @media (min-width: 1024px) {
          .Nav > ul {
            padding: var(--dist-1) 0;
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            gap: var(--global-gutter);
          }

          .Nav > ul li:first-child > .items {
            padding-left: var(--dist-1);
          }

          .Nav > ul li:last-child > .items {
            padding-right: var(--dist-1);
          }

          .Nav .items {
          }
        }
      `}</style>
    </>
  );
};

export default Nav;
