"use client";
// import { acceptedCategory } from "vanilla-cookieconsent";

const addCookieConsentListeners = () => {
  /**
   * React specific fix: avoid adding event listeners twice
   */
  if (window._ccRun) return;

  // window.addEventListener("cc:onConsent", () => {
  // });

  // window.addEventListener("cc:onChange", (event) => {
  //   const { changedCategories, changedServices } = event?.detail;

  //   // console.log("onChange:changedCategories", changedCategories);
  //   // console.log("onChange:changedServices", changedServices);

  //   // for (const category of changedCategories) {
  //   //   console.log(`${category} accepted:`, acceptedCategory(category));
  //   // }
  // });
};

export default addCookieConsentListeners;
