import useTranslation from "next-translate/useTranslation";
import { useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { BiErrorCircle, BiMinus, BiPlus, BiX } from "react-icons/bi";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { FaStarOfLife } from "react-icons/fa";
import useMeasure from "react-use-measure";

import Button from "./Button";

const Input = ({
  placeholder,
  type,
  label,
  required,
  prefix,
  suffix,
  onInputChange,
  ...props
}) => {
  const [localValue, setLocalValue] = useState(type === "number" ? "" : "");
  const { register, setValue, formState, clearErrors } = useFormContext(),
    { errors } = formState;

  const [refPrefix, boundsPrefix] = useMeasure();
  const [refSuffix, boundsSuffix] = useMeasure();
  const [refErrors, boundsErrors] = useMeasure();

  const { t } = useTranslation("common");

  const handleChange = (event) => {
    const value = event.target.value;
    onInputChange(value);
  };

  useEffect(() => {
    setValue(label, localValue, { shouldValidate: false });
  }, [localValue]);

  const patterns = {
    email: {
      value: new RegExp(/^\S+@\S+\.\S+$/),
      message: "Please enter a valid email",
    },
    phone: {
      value: new RegExp(
        /^\+?\d{1,3}[-.\s]?\(?\d{1,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}$/,
      ),
      message: "Please enter a valid phone number",
    },
    number: {
      value: new RegExp(/^\d+$/),
      message: "Must be greater than zero",
    },
  };

  return (
    <>
      <div
        className="Input"
        style={{
          "--w-prefix": boundsPrefix?.width,
          "--w-suffix": boundsSuffix?.width,
          "--h-errors": boundsErrors?.height,
        }}
        data-type={type}
        data-errors={!!errors?.[label]}
      >
        <label>
          {label}
          {required && "*"}
        </label>

        <div className="wrapper">
          <input
            type={type || "string"}
            placeholder={placeholder}
            rows={1}
            min="1"
            step="1"
            defaultValue={localValue}
            {...register(label, {
              required,
              onChange: (e) => {
                handleChange(e);
                if (errors?.[label]) {
                  clearErrors(label);
                }
              },
              pattern: patterns[type || "string"],
            })}
            onInput={(e) => {
              setLocalValue(e.target.value);
            }}
          />
        </div>
        {errors?.[label] && (
          <div className="errors" ref={refErrors}>
            {errors?.[label] && errors?.[label].type === "pattern" && (
              <span role="alert">{patterns?.[type]?.message}</span>
            )}
            {errors?.[label] && errors?.[label].type === "required" && (
              <span role="alert">{t("input-required")}</span>
            )}
          </div>
        )}
      </div>

      <style jsx global>{`
        .Input + .Input {
          margin-top: var(--dist-3);
        }

        /* Chrome, Safari, Edge, Opera */
        .Input input::-webkit-outer-spin-button,
        .Input input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        .Input input[type="number"] {
          -moz-appearance: textfield;
        }

        .Input > .wrapper {
          position: relative;
        }

        .Input {
          width: 100%;
          position: relative;
        }

        .Input label {
          font-size: var(--fs-xs);
          line-height: 1;
          font-family: "ABCROMMono-Regular";
          margin-bottom: var(--dist-1-5);
          display: flex;
          gap: 1ch;
        }

        .Input label svg {
          position: relative;
          top: 0em;
          font-size: 0.75em;
          color: red;
        }

        .Input input {
          overflow: hidden;
          resize: none;
          -webkit-appearance: none;
          border: 1px solid var(--color-primary);
          --pad: var(--dist-1);
          padding: var(--pad);
          width: 100%;
        }

        .Input[data-errors="true"] input {
          color: red;
        }

        .Input[data-type="number"] input {
          letter-spacing: 1ch;
        }

        .Input input {
        }

        .Input input:not(:empty) {
        }

        .Input input:focus {
          outline: 1px solid var(--color-primary);
        }

        .Input[data-errors="true"] input:not(:empty) {
        }

        input:focus {
        }

        .Input[data-errors="true"] input:focus {
          outline: 1px solid red;
        }

        .Input input::placeholder {
          color: #ccc;
        }

        .Input input:-webkit-autofill,
        .Input input:-webkit-autofill:hover,
        .Input input:-webkit-autofill:focus {
          outline-color: var(--color-primary);
          -webkit-text-fill-color: var(--color-primary);
          -webkit-box-shadow: 0 0 0px 1000px var(--color-primary) inset;
        }

        .Input[data-errors="true"] input:-webkit-autofill,
        .Input[data-errors="true"] input:-webkit-autofill:hover,
        .Input[data-errors="true"] input:-webkit-autofill:focus {
          outline-color: red;
          -webkit-text-fill-color: red;
          -webkit-box-shadow: 0 0 0px 1000px red inset;
        }

        .Input .errors {
          padding-top: var(--dist-1-5);
          color: red;
          font-size: var(--fs-xs);
          line-height: var(--lh-xs);
          display: flex;
          gap: 1ch;
        }

        .Input .errors svg {
          position: relative;
          top: 0.25em;
        }
      `}</style>
    </>
  );
};

export default Input;
