import { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { BiCheck } from "react-icons/bi";
import { FaStarOfLife } from "react-icons/fa";

// For Parent Component:
// const [isChecked, setIsChecked] = useState(false);

// const onChange = (newValue) => {
//   setIsChecked(newValue);
//   // Perform any additional logic or state updates in the parent component
// };

// <Checkbox text="Checkbox Label" checked={isChecked} onChange={onChange} />

const Checkbox = ({
  text,
  checked = false,
  required = false,
  group,
  onChange,
  error = false,
  presentation = false,
  ...props
}) => {
  const { register, setValue, control } = useFormContext() || {};

  const label = props?.label ?? text;

  const [localValue, setLocalValue] = useState(checked);

  useEffect(() => {
    if (!presentation) {
      register(`${group ? group + "." : ""}${label}`, {
        required,
        onChange: () => {
          if (errors?.[label]) {
            clearErrors(label);
          }
        },
      });

      setValue(`${group ? group + "." : ""}${label}`, checked);
    }
    setLocalValue(checked);
  }, [register, setValue, group, label, checked]);

  const handleCheckboxChange = () => {
    const newValue = !localValue;
    if (!presentation) {
      setValue(`${group ? group + "." : ""}${label}`, newValue);
    }
    setLocalValue(newValue);
    onChange?.(newValue);
  };

  return (
    <>
      <label className="Checkbox" data-active={localValue} data-errors={error}>
        <input
          type="checkbox"
          onChange={handleCheckboxChange}
          checked={localValue}
          required={required}
        />
        <span className="input"></span>
        <span>
          {text}
          {required && <FaStarOfLife />}
        </span>
      </label>

      <style jsx global>{`
        .Checkbox {
          display: flex;
          gap: 1ch;
          align-items: center;
          user-select: none;
          cursor: pointer;
          width: 100%;
          font-size: var(--fs-xs);
          line-height: var(--lh-sm);
          font-family: "ABCROMMono-Regular";
        }

        .Checkbox[data-active="true"] {
        }

        .Checkbox[data-errors="true"] {
          color: red;
        }

        .Checkbox span ~ span svg {
          position: relative;
          top: -1em;
          font-size: calc(0.75 * var(--fs-xs));
          color: red;
          display: inline;
          margin-left: 1ch;
        }

        .Checkbox span.input {
          width: var(--lh-sm);
          height: var(--lh-sm);
          font-size: var(--fs-sm);
          border: 1px solid var(--color-primary);
          display: flex;
          justify-content: center;
          align-items: center;
          aspect-ratio: 1;
          position: relative;
        }

        .Checkbox[data-active="true"] span.input::before {
          content: "";
          width: var(--lh-sm);
          height: 1px;
          position: absolute;
          top: 50%;
          left: calc(var(--lh-sm) / 2 - 1px);
          transform: rotate(45deg) translate(-50%, -50%);
          background: var(--color-primary);
          transform-origin: left top;
        }

        .Checkbox[data-active="true"] span.input::after {
          content: "";
          width: var(--lh-sm);
          height: 1px;
          position: absolute;
          top: 50%;
          left: calc(var(--lh-sm) / 2 - 1px);
          transform: rotate(-45deg) translate(-50%, -50%);
          background: var(--color-primary);
          transform-origin: left top;
        }

        .Checkbox input {
          position: absolute;
          clip: rect(1px, 1px, 1px, 1px);
          padding: 0;
          border: 0;
          height: 1px;
          width: 1px;
          overflow: hidden;
        }

        @media (max-width: 1023px) {
          .Checkbox span.input {
            border: 1px dotted;
          }

          .Checkbox[data-active="true"] span.input {
            border: 1px solid var(--color-primary);
          }
        }
      `}</style>
    </>
  );
};

export default Checkbox;
