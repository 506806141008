import { AnimatePresence, motion } from "framer-motion";

import Newsletter from "@/components/shared/Newsletter";

import Footer from "./Footer";
import Header from "./Header";
import { SiteMeta } from "./SiteMeta";

const Body = ({
  children,
  nav,
  footer,
  contact,
  newsletter,
  settings,
  data,
  loading,
  ...props
}) => {
  return (
    <>
      {/* <Seo seo={data?.page?.seo} title={data?.page?.title} /> */}
      <SiteMeta
        seo={data?.page?.seo}
        title={data?.page?.title}
        metadata={settings}
      />

      <Header
        nav={nav}
        footer={footer}
        translations={data?.page?._translations}
      />

      {children}
      <Footer footer={footer} contact={contact} loading={loading} />

      <Newsletter newsletter={newsletter} />

      {/* <style jsx global></style> */}
    </>
  );
};

export default Body;
