import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import { useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  GrAlert,
  GrClose,
  GrCompliance,
  GrLike,
  GrUpdate,
} from "react-icons/gr";

import { removeQueryParam } from "@/lib/utils";

import Button from "./Button";
import Checkbox from "./Checkbox";
import Drawer from "./Drawer";
import Input from "./Input";

const Newsletter = ({ newsletter }) => {
  const { note } = newsletter || {};

  const router = useRouter();

  const { t } = useTranslation("common");

  const [textInputValue, setTextInputValue] = useState("");

  const handleInputChange = (value) => {
    setTextInputValue(value);
  };

  const methods = useForm({ mode: "onBlur" }),
    { formState, watch } = methods;

  // 1. Create a reference to the input so we can fetch/clear it's value.
  const inputEl = useRef(null);
  // 2. Hold a message in state to handle the response from our API.
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState("input");

  const subscribe = async (e) => {
    e.preventDefault();
    if (!textInputValue || textInputValue === "") {
      //  If no value defined, update the message in state.
      setStatus("error");
      setMessage(t("empty"));

      return;
    }
    // 3. Send a request to our API with the user's email address.
    const res = await fetch("/api/newsletter", {
      body: JSON.stringify({
        email: textInputValue,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    });

    const { error } = await res.json();

    if (error) {
      // 4. If there was an error, update the message in state.
      setStatus("error");
      setMessage(`${error}`);

      return;
    }

    // 5. Clear the input value and show a success message.
    // inputEl.current.value = "";
    setStatus("success");
    setMessage(t("thanks"));
  };

  const icons = {
    input: <GrCompliance />,
    success: <GrLike />,
    error: <GrAlert />,
    idle: <GrUpdate />,
  };

  return router?.query?.newsletter !== "1" ? null : (
    <>
      <FormProvider {...methods}>
        <Drawer
          className="Newsletter"
          full
          isOpen
          onClose={() => {
            if (router.query.newsletter === "1") {
              removeQueryParam(router, "newsletter");
            }
          }}
        >
          <div className="msg">
            <h2>Newsletter</h2>
            <form
              onSubmit={subscribe}
              data-status={status}
              style={{
                color:
                  status === "success"
                    ? "var(--color-green)"
                    : status === "error"
                      ? "var(--color-red)"
                      : undefined,
              }}
            >
              <div className="wrapper">
                <Input
                  id="name-input"
                  name="name"
                  label="Name"
                  onInputChange={handleInputChange}
                  required
                />
                <Input
                  id="email-input"
                  name="email"
                  label="E-Mail"
                  onInputChange={handleInputChange}
                  required
                  type="email"
                  placeholder={"your@emailaddress.com"}
                />
                <Checkbox text={t("newsletter-accept")} />
                <Button
                  type={status !== "input" ? "button" : "submit"}
                  onClick={() => setStatus("idle")}
                >
                  {status === "idle" ? t("waiting") : t("subscribe")}
                </Button>
              </div>

              <div
                className="msg"
                onClick={() => {
                  setStatus("input");
                  setMessage(null);
                }}
              >
                {message}
              </div>
            </form>
            <section>{note?.[router?.locale]}</section>
          </div>
        </Drawer>
      </FormProvider>

      <style jsx global>
        {`
          .Newsletter .msg {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: var(--global-gutter);
          }

          .Newsletter h2 {
            font-size: var(--fs-lg);
            line-height: var(--lh-lg);
            letter-spacing: var(--ls-lg);
            margin-bottom: var(--dist-3);
            grid-column: span 2;
          }

          .Newsletter .Button,
          .Newsletter .Checkbox {
            margin-top: var(--dist-3);
          }

          .Newsletter :is(section, form) {
            flex-shrink: 1;
          }
        `}
      </style>
    </>
  );
};

export default Newsletter;
