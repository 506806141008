"use client";

import "vanilla-cookieconsent/dist/cookieconsent.css";

import { useRouter } from "next/router";
import { useEffect } from "react";
import * as CookieConsent from "vanilla-cookieconsent";

import getConfig from "./CookieConsentConfig";
import addEventListeners from "./CookieConsentListener";

/**
 * This example uses custom event listeners.
 * You can use either event listeners or callback functions:
 * https://cookieconsent.orestbida.com/advanced/callbacks-events.html
 */

const ResetCookieConsent = () => {
  CookieConsent.reset(true);
  CookieConsent.run(getConfig());
};

const CookieConsentComponent = () => {
  const router = useRouter();
  useEffect(() => {
    addEventListeners();
    CookieConsent.run(getConfig());
  }, []);

  useEffect(() => {
    if (router?.query?.cookie === "1") {
      CookieConsent.showPreferences();
      const { query } = router;
      const { cookie, ...rest } = query;
      router.replace({
        pathname: router.pathname,
        query: rest,
      });
    }
  }, [router?.query?.cookie]);

  return (
    <div>
      {/* <button type="button" onClick={CookieConsent.showPreferences}>
        Manage cookie preferences
      </button>
      <button type="button" onClick={ResetCookieConsent}>
        Reset cookie consent
      </button> */}
      <style jsx global>{`
        :root {
          {/* Fonts */}
          --cc-font-family: "ABCROM-Regular";
          --cc-link-color: var(--color-primary);
          
          {/* Misc / Colors */}
          --cc-primary-color: var(--color-primary);
          --cc-secondary-color: var(--color-primary);
          --cc-modal-border-radius: 0;
          --cc-bg: var(--color-secondary);
          --cc-separator-border-color: var(--color-primary);
          
          {/* Buttons */}
          --cc-btn-border-radius: 99999px;
          --cc-btn-primary-bg: var(--color-primary);
          --cc-btn-primary-color: var(--color-secondary);
          --cc-btn-primary-hover-color: var(--color-primary);
          --cc-btn-primary-hover-bg: var(--color-secondary);
          --cc-btn-primary-border-color: var(--color-primary);
          --cc-btn-primary-hover-border-color: var(--color-primary);
          --cc-btn-secondary-bg: var(--color-secondary);
          --cc-btn-secondary-color: var(--color-primary);
          --cc-btn-secondary-hover-color: var(--color-secondary);
          --cc-btn-secondary-hover-bg: var(--color-primary);
          --cc-btn-secondary-border-color: var(--color-primary);
          --cc-btn-secondary-hover-border-color: var(--color-primary);
          --cc-cookie-category-block-bg: var(--color-secondary);
          --cc-section-category-border: var(--color-primary);
          --cc-cookie-category-block-border: var(--color-primary);
          --cc-cookie-category-block-hover-border: var(--color-primary);
          --cc-cookie-category-expanded-block-hover-bg: var(--color-primary);
          --cc-toggle-readonly-bg: var(--color-primary);
          --cc-overlay-bg: var(--color-secondary-overlay);
        }
        #cc-main .cm, #cc-main .pm {
          box-shadow: none;
          border: 1px solid var(--color-primary);
        }
        #cc-main .cm__btn, #cc-main .pm__btn {
          font-size: var(--fs-xs);
          line-height: var(--lh-sm);
          font-family: "ABCROMMono-Regular";
          font-weight: normal !important;
          padding: 0 0.75em;
          min-height: unset;
          {/* border: 1px solid var(--color-primary); */}
        }
        #cc-main .cm__title, #cc-main a, #cc-main b, #cc-main em, #cc-main strong {
          font-weight: normal;
        }
        #cc-main .cm__desc, #cc-main .cm__title {
          padding: 0 var(--dist-1-5);

        }
        #cc-main .cm__texts {
          padding-top: var(--dist-1-5);
        }
        #cc-main .cm__desc {
          padding-bottom: var(--dist-1-5);

        }
        #cc-main .cm__btns, #cc-main .cm__links {
          padding: var(--dist-1-5);
        }

        #cc-main .pm__section--toggle .pm__section-desc-wrapper, #cc-main .pm__section-title {
          border-radius: 0;
        }
        #cc-main .pm__section--expandable .pm__section-arrow svg {
          stroke: var(--color-secondary);
        }

        #cc-main .pm__title, #cc-main .pm__section-title {
          font-weight: normal;
        }
        #cc-main .pm__badge {
          border-radius: 0;
          background: none;
          color: inherit;
          }
      `}</style>
    </div>
  );
};

export default CookieConsentComponent;
