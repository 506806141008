
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import "../global.css";
import { AnimatePresence } from "framer-motion";
import Router, { useRouter } from "next/router";
import { groq } from "next-sanity";
import { lazy, Suspense, useEffect, useState } from "react";
import Body from "@/components/global/Body";
import useGlobalStore from "@/hooks/useGlobalStore";
import useScrollbarWidth from "@/hooks/useScrollbarWidth";
import { getClient } from "@/lib/sanity.client";
import CookieConsentComponent from "../components/utils/CookieConsent/CookieConsent";
const PreviewProvider = lazy(() => import("@/components/preview/PreviewProvider"));
function App({ Component, pageProps, globalProps }) {
    const { draftMode, token } = pageProps || {};
    useEffect(() => {
        useGlobalStore.setState((state) => ({ ...state, globalProps })); // Update only the globalProps within the global state
    }, [globalProps]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        // Used for page transition
        const start = () => {
            setLoading(true);
        };
        const end = () => {
            setLoading(false);
        };
        Router.events.on("routeChangeStart", start);
        Router.events.on("routeChangeComplete", end);
        Router.events.on("routeChangeError", end);
        return () => {
            Router.events.off("routeChangeStart", start);
            Router.events.off("routeChangeComplete", end);
            Router.events.off("routeChangeError", end);
        };
    }, []);
    useScrollbarWidth();
    const router = useRouter();
    return (<>
      <Body {...pageProps} {...globalProps} loading={loading}>
        {draftMode ? (<PreviewProvider token={token}>
            <AnimatePresence mode="wait" initial={false} onExitComplete={() => window.scrollTo(0, 0)}>
              <Component {...pageProps} key={router?.asPath} loading={loading}/>
            </AnimatePresence>
          </PreviewProvider>) : (<AnimatePresence mode="wait" initial={false} onExitComplete={() => window.scrollTo(0, 0)}>
            <Component {...pageProps} key={router?.asPath} loading={loading}/>
          </AnimatePresence>)}
      </Body>

      <CookieConsentComponent key="cookie"/>

      {!globalProps?.settings?.isRunning && (<style jsx global>{`
          :root {
            --color-secondary: hsl(0, 0%, 10%);
            --color-primary: var(--color-white);
            --color-secondary-light: hsl(0, 0%, 2%);
            --color-secondary-overlay: hsla(0 100% 0% / 0.9);
          }
        `}</style>)}
    </>);
}
App.getInitialProps = async (ctx) => {
    const { draftMode = false } = ctx;
    const client = getClient();
    const data = await client.fetch(groq `
  {
    "nav": *[_type == "nav"][0]{..., menu[]{..., items[]{...,reference->{_type, title, slug, _type, 
      "_translations": *[_type == "translation.metadata" && references(^._id)].translations[].value->{
        _type, title, slug, language
      }  
    }}}},
    "footer": *[_type == "footer"][0]{..., menu[]{..., items[]{...,reference->{_type, title, slug, _type, 
      "_translations": *[_type == "translation.metadata" && references(^._id)].translations[].value->{
        _type, title, slug, language
      }  
    }}}},
    "contact": *[_type == "contact"][0],
    "newsletter": *[_type == "newsletter"][0],
    "settings": *[_type == "settings"][0]
  }
`);
    const { nav, footer, contact, newsletter, settings } = data;
    return {
        globalProps: {
            nav,
            footer,
            contact,
            newsletter,
            settings,
        },
    };
};

    export default __appWithI18n(App, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  