import nodeCrypto from "crypto";
import React from "react";
import slugify from "slugify";

import {
  BASE_LANGUAGE,
  CREATED_AT,
  MULTILINGUAL,
  SUPPORTED_LANGUAGES,
} from "@/config";
import { dataset, projectId } from "@/lib/sanity.api";

export function createRandomUUID() {
  if (typeof crypto !== "undefined") {
    return crypto.randomUUID();
  }

  return nodeCrypto.randomUUID();
}

// Render Emoji
export const renderEmoji = (emoji) => () => (
  <span className="iconEmoji" role="img" style={{ fontSize: "1.25em" }}>
    {emoji}
  </span>
);

// Render Media
export const renderMedia = (media) => {
  const { type, image, video } = media || {};
  if (!image && !video) return undefined;
  return type === "image" ? (
    image
  ) : (
    /* eslint-disable @next/next/no-img-element */
    <img
      alt="Video Preview"
      style={{ objectFit: "cover" }}
      src={`https://image.mux.com/${video?.playbackId}/animated.gif?end=2.5&width=400&time=0`}
    />
  );
};

export const formatBytes = (bytes, decimals = 2, locale = "en") => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024,
    dm = decimals < 0 ? 0 : decimals,
    sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));

  const size =
    new Intl.NumberFormat(locale).format(
      parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
    ) +
    " " +
    sizes[i];

  return size;
};

// Render GIF
export const renderGIF = (playbackId) =>
  playbackId && (
    <img
      alt="Video Preview"
      style={{ width: "100%", height: "100%", objectFit: "cover" }}
      src={`https://image.mux.com/${playbackId}/animated.gif?end=2.5&width=400&time=0`}
    />
  );

export const formatPrice = (val) => {
  return new Intl.NumberFormat(BASE_LANGUAGE?.id || "en", {
    currency: "eur",
    style: "currency",
  })
    .format(val)
    .replace(".00", "")
    .replace(",", "");
};

export const getBrightness = (hexColor) => {
  if (!hexColor) return "dark";

  // Convert hex color to RGB
  const red = parseInt(hexColor?.substring(1, 3), 16);
  const green = parseInt(hexColor?.substring(3, 5), 16);
  const blue = parseInt(hexColor?.substring(5, 7), 16);

  // Calculate perceived brightness
  const perceivedBrightness = Math.sqrt(
    0.299 * red * red + 0.587 * green * green + 0.114 * blue * blue,
  );

  // Return "dark" or "bright"
  if (perceivedBrightness <= 190) {
    return "dark";
  } else {
    return "bright";
  }
};

export const toPlainText = (blocks = []) => {
  return Array.isArray(blocks)
    ? blocks
        // loop through each block
        .map((block) => {
          // if it's not a text block with children,
          // return nothing
          if (block._type !== "block" || !block.children) {
            return "";
          }
          // loop through the children spans, and join the
          // text strings
          return block.children.map((child) => child.text).join("");
        })
        // join the paragraphs leaving split by two linebreaks
        .join("\n\n")
    : blocks;
};

export const getFileUrl = (_ref) =>
  _ref &&
  `https://cdn.sanity.io/files/${projectId}/${dataset}/${_ref.split("-")[1]}.${
    _ref.split("-")[2]
  }?dl=`;

export const getProportions = (ratioNative, orientationNative, ratioCustom) => {
  if (!ratioCustom)
    return { orientation: orientationNative, ratio: ratioNative };

  if (Array.isArray(ratioCustom)) {
    const ratios = [Math.max(...ratioCustom), Math.min(...ratioCustom)],
      ratio = ratios[orientationNative === "portrait" ? 1 : 0],
      orientation = ratio < 1 ? "portrait" : "landscape";
    return { orientation, ratio };
  } else {
    const orientation = ratioCustom < 1 ? "portrait" : "landscape";
    return { orientation, ratio: ratioCustom };
  }
};

export async function isUniqueInLanguage(slug, context, _types) {
  !_types && console.warn("_types not defined for isUnique()");
  Object.prototype.toString.call(_types) !== "[object Array]" &&
    console.warn("_types must be an array");
  const { document, getClient } = context,
    client = getClient({ apiVersion: CREATED_AT }),
    id = document._id.replace(/^drafts\./, ""),
    params = {
      slug,
      draft: `drafts.${id}`,
      published: id,
      language: context?.document?.language || "",
      _types,
    },
    query =
      "!defined(*[_type in _types && !(_id in [$draft, $published]) && slug.current == $slug && language == $language][0]._id)",
    result = await client.fetch(query, params);
  return result;
}

export const getLanguageFromUrl = () => {
  const url = window.location.href;
  const deskIndex = url.indexOf("/desk");
  if (deskIndex === -1) {
    // "/desk" not found in URL
    return null;
  }
  const parts = url.substring(deskIndex + 6).split(";");
  if (parts.length < 1) {
    // no parts found after "/desk"
    return null;
  }
  const language = parts[0];

  return MULTILINGUAL
    ? (SUPPORTED_LANGUAGES.find(
        (lang) => slugify(lang.title, { lower: true }) === language,
      )?.id ?? BASE_LANGUAGE?.id) + "/"
    : "";
};

export const getHostnameFromCurrentURL = () => {
  if (typeof window !== "undefined") {
    const currentURL = window.location.href;
    const parsedUrl = new URL(currentURL);
    let hostWithPort = parsedUrl.host.replace(/^www\./, ""); // Remove 'www.' if present
    return hostWithPort;
  }
};

export const formatDate = (startDateIso, endDateIso = null) => {
  const formatDate = (date) => {
    return `${date.getDate().toString().padStart(2, "0")}.${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}.${date.getFullYear().toString().slice(2)}`;
  };
  const startDate = new Date(startDateIso);
  if (!endDateIso || endDateIso === startDateIso) {
    return formatDate(startDate);
  }
  const endDate = new Date(endDateIso);
  if (startDate.getFullYear() === endDate.getFullYear()) {
    return `${startDate.getDate().toString().padStart(2, "0")}.${(
      startDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}.–${formatDate(endDate)}`;
  } else {
    return `${formatDate(startDate)}–${formatDate(endDate)}`;
  }
};

export const removeQueryParam = (router, param) => {
  const { pathname, query } = router;

  router.replace({ pathname, query: { ...query, [param]: null } }, undefined, {
    scroll: false,
  });
};
