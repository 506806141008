import { useRouter } from "next/router";

import Hyperlink from "@/components/shared/Hyperlink";
import useHasMounted from "@/hooks/useHasMounted";

const NavFooter = ({ footer }) => {
  const { menu } = footer || {};

  const hasMounted = useHasMounted();

  const { locale } = useRouter();

  return !hasMounted ? null : (
    <>
      <nav className="NavFooter">
        <ul>
          {menu?.map(({ _key, title, items }) => (
            <li key={_key}>
              <ul className="items">
                {items?.map(({ _key, ...link }) => (
                  <li key={_key}>
                    <Hyperlink link={link} />
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </nav>

      <style jsx global>{`
        .NavFooter {
          padding: var(--dist-1) 0;
        }

        @media (max-width: 1023px) {
          .NavFooter > ul {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: var(--dist-1);
          }

          .NavFooter > ul > li:nth-child(odd) {
            padding-left: var(--dist-1);
          }

          .NavFooter > ul > li:nth-child(even) {
            padding-right: var(--dist-1);
          }
        }
      `}</style>
    </>
  );
};

export default NavFooter;
