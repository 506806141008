function gtag() {
  dataLayer.push(arguments);
}
export function initGA() {
  const disableStr = "ga-disable-G-JGX0Y38V33";

  document.cookie =
    disableStr + "=false; Expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/";

  window.dataLayer = window.dataLayer || [];
  gtag("js", new Date());
  gtag("config", "G-JGX0Y38V33", { anonymize_ip: true });

  var s = document.createElement("script");
  s.type = "text/javascript";
  s.async = "true";
  s.src = "https://www.googletagmanager.com/gtag/js?id=G-JGX0Y38V33";
  var x = document.getElementsByTagName("script")[0];
  x.parentNode.insertBefore(s, x);
}

export function deleteCookies(cookieconsent_name) {
  var keep = [cookieconsent_name, "DYNSRV"];

  document.cookie.split(";").forEach(function (c) {
    c = c.split("=")[0].trim();
    if (!~keep.indexOf(c))
      document.cookie =
        c + "=;" + "expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
  });
}

// Opt-out function
export function optOutGA() {
  const disableStr = "ga-disable-G-JGX0Y38V33";
  document.cookie =
    disableStr + "=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/";
  window[disableStr] = true;
}
