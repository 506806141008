import { useEffect, useRef, useState } from "react";
import { Drawer } from "vaul";

const DrawerContainer = ({
  className,
  children,
  onClose,
  isOpen = undefined,
}) => {
  const [open, setOpen] = useState(!!isOpen);

  const drawerRestorePosition = useRef();

  return (
    <>
      <Drawer.Root
        open={open}
        onOpenChange={(isOpen) => {
          !isOpen && onClose?.();
          setOpen(isOpen);
        }}
      >
        <Drawer.Portal>
          <Drawer.Content className={[className, "Drawer"].join(" ")}>
            <div
              className="outer"
              onClick={({ target }) =>
                !target.closest(".wrapper") && setOpen(false)
              }
            >
              <div className="inner">
                <div className="wrapper">
                  <Drawer.Trigger
                    style={{ pointerEvents: "auto" }}
                    className="close"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 31 30"
                    >
                      <path
                        stroke="#000"
                        d="m1.648 29.646 29-28.999M.652.646l29 29"
                      />
                    </svg>
                  </Drawer.Trigger>
                  {children}
                </div>
              </div>
            </div>
          </Drawer.Content>
          <Drawer.Overlay className="Overlay" />
        </Drawer.Portal>
      </Drawer.Root>

      <style jsx global>{`
        .Drawer:focus,
        .Drawer *:focus,
        button,
        a {
          outline: unset !important;
        }

        [vaul-drawer] {
          touch-action: none;
          transition: none !important;
        }

        .Drawer {
          position: fixed;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: 9999;
        }

        .Drawer .outer {
          display: block;
          position: relative;
          overflow: auto;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: var(--dist-1-5);
        }

        .Drawer .inner {
          width: calc(var(--global-column) * 8 + var(--global-gutter) * 7);
          background: var(--color-secondary);
          padding: var(--dist-1-5);
          position: relative;
          border: 1px solid var(--color-primary);
        }

        .Drawer .inner > .wrapper > button {
          position: absolute;
          top: var(--dist-1-5);
          right: var(--dist-1-5);
        }

        .Overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          backdrop-filter: blur(1px);
          background: var(--color-secondary-overlay);
          z-index: 0;
        }

        .Drawer .close svg {
          width: auto;
          height: var(--dist-3);
        }
      `}</style>
    </>
  );
};

export default DrawerContainer;
