import { capitalize } from "lodash";
import Link from "next/link";
import { useRouter } from "next/router";

import { SUPPORTED_LANGUAGES } from "@/config";

const LanguageSelector = ({ translations }) => {
  const router = useRouter();

  return (
    <>
      <div className="LanguageSelector">
        {translations?.length > 0 && translations?.[0]?.language
          ? translations
              ?.filter(Boolean)
              .map(({ _type, language, slug }, i) => (
                <Link
                  key={i}
                  href={{
                    pathname: `/${["page", "home"].includes(_type) ? "" : _type + "/"}${slug?.current || ""}`,
                  }}
                  locale={language}
                  data-active={language === router?.locale}
                  scroll={false}
                >
                  {capitalize(language)}
                </Link>
              ))
          : SUPPORTED_LANGUAGES?.map(({ id }, i) => (
              <Link
                key={i}
                href={{
                  pathname: "/",
                }}
                locale={id}
                data-active={id === router?.locale}
                scroll={false}
              >
                {capitalize(id)}
              </Link>
            ))}
      </div>

      <style jsx global>{`
        .LanguageSelector {
          display: flex;
          gap: 1ch;
          padding: var(--dist-1);
          text-transform: uppercase;
        }

        .LanguageSelector [data-active="true"] {
          text-decoration: underline;
        }
      `}</style>
    </>
  );
};

export default LanguageSelector;
