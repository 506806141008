import * as AccordionPrimitive from "@radix-ui/react-accordion";
import React from "react";

const Accordion = ({ children, type = "single" }) =>
  !children || children?.length < 1 ? null : (
    <>
      <AccordionPrimitive.Root
        className="AccordionPrimitiveRoot"
        type={type}
        defaultValue="item-1"
        collapsible
      >
        {children}
      </AccordionPrimitive.Root>

      <style jsx global>
        {`
          /* reset */
          button,
          h3 {
            all: unset;
          }

          .AccordionPrimitiveHeader {
            display: flex;
          }

          .AccordionPrimitiveTrigger {
            font-family: inherit;
            background-color: transparent;

            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .AccordionPrimitiveContent {
            overflow: hidden;
          }
          .AccordionPrimitiveContent[data-state="open"] {
            animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
          }
          .AccordionPrimitiveContent[data-state="closed"] {
            animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
          }

          @keyframes slideDown {
            from {
              height: 0;
            }
            to {
              height: var(--radix-accordion-content-height);
            }
          }

          @keyframes slideUp {
            from {
              height: var(--radix-accordion-content-height);
            }
            to {
              height: 0;
            }
          }
        `}
      </style>
    </>
  );

export const AccordionItem = React.forwardRef(
  ({ children, className, value = "", ...props }, forwardedRef) => (
    <AccordionPrimitive.Item className="AccordionPrimitiveItem" value={value}>
      {children}
    </AccordionPrimitive.Item>
  ),
);

export const AccordionTrigger = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <AccordionPrimitive.Header className="AccordionPrimitiveHeader">
      <AccordionPrimitive.Trigger
        className={["AccordionPrimitiveTrigger", className]
          ?.filter(Boolean)
          .join(" ")}
        {...props}
        ref={forwardedRef}
      >
        {children}
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  ),
);

export const AccordionContent = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <AccordionPrimitive.Content
      className={["AccordionPrimitiveContent", className]
        ?.filter(Boolean)
        .join(" ")}
      {...props}
      ref={forwardedRef}
    >
      <div className="AccordionPrimitiveContentText">{children}</div>
    </AccordionPrimitive.Content>
  ),
);

export default Accordion;
