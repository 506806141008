import { useRouter } from "next/router";

import LanguageSelector from "@/components/shared/LanguageSelector";

import Menu from "./Menu";
import Nav from "./Nav";
import NavFooter from "./NavFooter";

const Header = ({ nav, footer, translations }) => {
  const router = useRouter();

  return (
    <>
      <header className="Header">
        <Menu nav={nav}>
          <Nav nav={nav} />
          <NavFooter footer={footer} />
          <LanguageSelector translations={translations} />
        </Menu>
        <LanguageSelector translations={translations} />
      </header>

      <style jsx global>{`
        .Header {
          position: sticky;
          top: 0;
          left: 0;
          width: 100%;
          padding: var(--dist-1);
          font-size: var(--fs-xs);
          line-height: var(--lh-xs);
          z-index: 999;
          font-family: "ABCROMMono-Regular";
        }

        @media (max-width: 1023px) {
          .Header .LanguageSelector {
            margin-top: calc(
              (var(--dist-7) - 2 * var(--dist-1))
            ); // Compensate for own and sibling padding
          }
          .Header > .LanguageSelector {
            display: none;
          }

          .Header .LanguageSelector:last-child {
            margin-bottom: calc(
              var(--global-column)+ (var(--dist-7) - 1 * var(--dist-1))
            );
          }
        }

        @media (min-width: 1024px) {
          .Header .NavFooter,
          .Header > * .LanguageSelector {
            display: none;
          }

          .Header > .LanguageSelector {
            position: absolute;
            top: var(--dist-1);
            right: var(--dist-1);
            z-index: 999;
          }
        }
      `}</style>
    </>
  );
};

export default Header;
