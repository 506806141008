import { motion } from "framer-motion";
import Link from "next/link";
import { Children } from "react";

const Button = ({
  className,
  children,
  onClick,
  href,
  locale,
  type,
  style,
  indicator = undefined,
  sm,
  active = undefined,
  disabled = undefined,
  ...props
}) => {
  return (
    <>
      {!href ? (
        <motion.button
          className={["Button", className].filter(Boolean).join(" ")}
          onClick={disabled ? undefined : onClick}
          data-active={active}
          style={{
            ...style,
          }}
          {...props}
        >
          {Children.map(children, (child) => {
            if (typeof child === "string") {
              return <span>{child}</span>;
            }
            return child;
          })}
        </motion.button>
      ) : (
        <Link
          href={href}
          locale={locale}
          data-active={active}
          className={["Button", className].join(" ")}
          scroll={false}
        >
          {Children.map(children, (child) => {
            if (typeof child === "string") {
              return <span>{child}</span>;
            }
            return child;
          })}
        </Link>
      )}

      <style jsx global>{`
        .Button {
          position: relative;
          font-size: var(--fs-xs);
          line-height: var(--lh-sm);
          font-family: "ABCROMMono-Regular";
          padding: calc(var(--lh-sm) / 4) 0.75em;
          cursor: pointer;
          white-space: nowrap;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          align-self: flex-start;


          font-weight: normal;
          border: 0.5px solid transparent;
          border-radius: 99999px;
  
          overflow: hidden;
          background: var(--color-primary);
          color: var(--color-secondary);
          transition:
              background-color 0.2s,
              color 0.2s;

        }
        @media (hover: hover) {
            .Button:hover {
              color: var(--color-primary);
              background: var(--color-secondary);
            }
          }

        .Button span,
        .Button svg {
          z-index: 1;
        }

        .Button:not([data-size="sm"]) svg:not(:last-child) {
          margin-right: 0.5em;
        }

        .Button[data-disabled="true"] {
          opacity: 0.5;
          cursor: not-allowed;
        }

      

        @media (hover: hover) {
          .Button:not([data-disabled="true"]):not([data-active="true"]):hover {
            z-index: 999999;

            {/* background: hsla(0, 0%, 100%, 1);
            color: hsla(0, 0%, 0%, 1); */}
          }
        }
      `}</style>
    </>
  );
};

export default Button;
