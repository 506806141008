import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import { useEffect } from "react";

import { useNavStore } from "@/hooks/useStore";

const Menu = ({ children, nav, footer }) => {
  const { showNav, setNav } = useNavStore();

  const { locale, asPath } = useRouter();
  const { t } = useTranslation("common");

  useEffect(() => {
    setNav(false);
  }, [asPath, setNav]);

  return (
    <>
      <div className="Menu">
        <div className="wrapper">
          <ul
            className="title"
            data-type="desktop"
            onClick={({ target }) => {
              setNav(!showNav);
            }}
          >
            {nav?.menu?.map(({ _key, title, items }) => (
              <li key={_key}>{title?.[locale]}</li>
            ))}
            {nav.menu &&
              nav.menu.length < 7 &&
              Array.from({ length: 7 - nav.menu.length }, (_, index) => (
                <li key={`extra_${index}`}></li>
              ))}
          </ul>
          <div
            className="title"
            data-type="mobile"
            onClick={() => setNav(!showNav)}
          >
            <h1>{t("title")}</h1>
            <h1 data-type="mini">RAY</h1>
            <span>{!showNav ? t("menu-open") : t("menu-close")}</span>
          </div>
          <div
            className="accordionContent"
            role="region"
            aria-hidden={!showNav}
          >
            <div>{children}</div>
          </div>
        </div>
      </div>

      <style jsx global>{`
        .Menu {
          position: relative;
          border: 1px solid var(--color-primary);
          background: var(--color-secondary);
        }

        .Menu > .wrapper {
        }

        .Menu > .wrapper {
          position: relative;
          max-height: calc(100 * var(--svh) - 2 * var(--dist-1));
          overflow: auto;
        }

        .Menu .wrapper > .title[data-type="desktop"] {
          display: flex;
          justify-content: space-between;
          padding: var(--dist-1) 0;

          text-transform: uppercase;
        }

        .Menu .wrapper > .title[data-type="desktop"] {
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          gap: 0 var(--global-gutter);
          padding: var(--dist-1) 0;

          text-transform: uppercase;
        }

        .Menu .wrapper > .title[data-type="desktop"] li:first-child {
          padding-left: var(--dist-1);
        }

        .Menu .wrapper > .title[data-type="desktop"] li:last-child {
          padding-right: var(--dist-1);
        }

        .Menu .wrapper > .title[data-type="mobile"] {
          display: flex;
          justify-content: space-between;
          padding: var(--dist-1);

          text-transform: uppercase;
        }

        .Menu .accordionContent {
          display: grid;
          grid-template-rows: 0fr;
        }

        .Menu > .wrapper .accordionContent {
          transition: grid-template-rows 300ms cubic-bezier(0.87, 0, 0.13, 1);
        }

        .Menu .accordionContent[aria-hidden="false"] {
          grid-template-rows: 1fr;
        }

        @media (min-width: 1024px) and (hover: hover) {
          .Menu:hover .accordionContent[aria-hidden="true"] {
            grid-template-rows: 1fr;
          }
        }

        .Menu > .wrapper .accordionContent > div {
          overflow: hidden;
          grid-row: 1 / span 2;
        }

        @media (max-width: 389px) {
          .Menu h1:not([data-type="mini"]) {
            display: none;
          }
        }

        @media (min-width: 390px) {
          .Menu h1[data-type="mini"] {
            display: none;
          }
        }

        @media (max-width: 1023px) {
          .Menu .wrapper > .title[data-type="desktop"] {
            display: none;
          }
        }

        @media (min-width: 1024px) {
          .Menu .wrapper > .title[data-type="mobile"] {
            display: none;
          }
        }
      `}</style>
    </>
  );
};

export default Menu;
