import Head from "next/head";
import { NextSeo } from "next-seo";

import { TITLE } from "@/config";
import { getImgUrl } from "@/lib/sanity.image";

export function SiteMeta({ title, seo, metadata }) {
  const { defaultSeo } = metadata || {};

  const {
    index = true,
    follow = true,
    description,
    preview,
  } = { ...defaultSeo, ...seo } || {};

  return (
    <>
      <NextSeo
        title={title}
        titleTemplate={"RAY | %s"}
        defaultTitle={TITLE}
        description={description}
        noindex={!index}
        nofollow={!follow}
        openGraph={{
          type: "website",
          title,
          description,
          site_name: TITLE,
          images: !preview
            ? undefined
            : [
                {
                  url: getImgUrl(preview),
                  width: 1200,
                  height: 630,
                },
              ],
        }}
      />

      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/favicon/safari-pinned-tab.svg"
          color="#aaaaaa"
        />
        <meta name="msapplication-TileColor" content="#aaaaaa" />
        <meta name="theme-color" content="#aaaaaa" />
      </Head>
    </>
  );
}
