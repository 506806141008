import Link from "next/link";
import { useRouter } from "next/router";

import { getFileUrl } from "@/lib/utils";

const Hyperlink = ({ children, className, Icon = undefined, ...props }) => {
  const { locale, asPath, pathname, query } = useRouter();

  if (!props?.link && !children) return null;
  if (!props?.link && children) return children;

  const { href, text, type, file, blank, reference } = props?.link || {};

  const link = {
    external: { title: href, href },
    internal: {
      title: reference?.title,
      href:
        [
          ["page", "home"].includes(reference?._type) ? null : reference?._type,
          reference?.slug?.current,
        ]
          ?.filter(Boolean)
          ?.join("/") || "",
    },
    file: { title: file, href: getFileUrl(file?.asset?._ref) },

    tel: { title: href, href },
    email: { title: href, href },
  };

  let typeParsed;

  if (type !== "external") {
    typeParsed = type;
  } else {
    if (!["mailto:", "tel:"].some((prefix) => href?.startsWith(prefix))) {
      typeParsed = type;
    } else if (href?.startsWith("tel:")) {
      typeParsed = "tel";
    } else {
      typeParsed = "email";
    }
  }

  let funcQuery;

  if (typeParsed === "function") {
    switch (props?.link?.function) {
      case "show-cookie-modal":
        funcQuery = {
          cookie: "1",
        };
        break;
      // Newsletter fallback
      default:
        funcQuery = {
          newsletter: "1",
        };
        break;
    }
  }

  return (
    <>
      {/*eslint-disable */}
      {typeParsed === "function" ? (
        <Link
          className={["Hyperlink", className]?.filter(Boolean)?.join(" ")}
          data-type={type}
          href={{
            pathname,
            query: { ...query, ...funcQuery },
          }}
          passHref
          shallow
          replace
          scroll={false}
        >
          {children || text?.[locale] || text || link?.[typeParsed]?.title}
          {Icon && <Icon />}
        </Link>
      ) : ["external", "file"].includes(typeParsed) ? (
        <a
          className={["Hyperlink", className]?.filter(Boolean)?.join(" ")}
          data-type={type}
          href={link?.[typeParsed]?.href}
          target={
            ["external", "file"].includes(typeParsed) && blank
              ? "_blank"
              : "_self"
          }
          rel={
            ["external", "file"].includes(typeParsed) && blank
              ? "noopener noreferrer"
              : undefined
          }
        >
          {children || text?.[locale] || text || link?.[typeParsed]?.title}
          {Icon && <Icon />}
        </a>
      ) : (
        <Link
          className={["Hyperlink", className]?.filter(Boolean)?.join(" ")}
          data-type={type}
          scroll={false}
          href={
            (type === "internal"
              ? "/"
              : type === "function"
                ? asPath + "/"
                : "") + link?.[typeParsed]?.href
          }
        >
          {children || text?.[locale] || text || link?.[typeParsed]?.title}
          {Icon && <Icon />}
        </Link>
      )}
      {/*eslint-enable */}

      <style jsx global>
        {`
          .Hyperlink {
            justify-content: space-between;
            align-items: center;
            gap: var(--dist-1);
          }

          .Hyperlink svg {
            width: auto;
            height: var(--fs-md-h);
          }

          .Hyperlink.Button {
            display: flex;
            justify-self: flex-start;
            margin-top: var(--dist-3);
            border: 1px solid var(--color-primary);
            border-radius: 99999px;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: auto;
            height: var(--dist-3);
            padding: 0 0.75em;
            font-size: var(--fs-xs);
            line-height: var(--lh-xs);
            font-family: "ABCROMMono-Regular";
            max-width: max-content;
            transition:
              background-color 0.2s,
              color 0.2s;
          }
          @media (hover: hover) {
            .Hyperlink.Button:hover {
              background: var(--color-primary);
              color: var(--color-secondary);
            }
          }

          .Hyperlink.Button + .Hyperlink.Button {
            margin-top: var(--dist-1);
          }

          .Hyperlink.Button:first-child {
            margin-top: 0;
          }

          .Hyperlink.Link {
            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-underline-offset: 3px;
          }
          @media (hover: hover) {
            .Hyperlink.Link:hover {
              transition: opacity 0.2s;
            }
            .Hyperlink.Link:hover {
              text-decoration-color: var(--color-primary);
              opacity: 0.5;
            }
          }
        `}
      </style>
    </>
  );
};

export default Hyperlink;
