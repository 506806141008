export const TITLE = "RAY Triennale";
export const URL = "http://localhost:3000/";
export const CREATED_AT = "2024-01-22";

export const SUPPORTED_LANGUAGES = [
  { id: "de", title: "Deutsch", icon: "🇩🇪", isDefault: true },
  { id: "en", title: "English", icon: "🇬🇧" },
];

export const MULTILINGUAL = SUPPORTED_LANGUAGES?.length > 1;

export const BASE_LANGUAGE =
  SUPPORTED_LANGUAGES.find((l) => l.isDefault) || SUPPORTED_LANGUAGES?.[0];

// Specify Platform for hosting, deployment, etc.
export const PLATFORM = ["netlify"];

export const PREVIEW_BASE_URL = "/api/draft";

export const SINGLETONS = ["home"];
export const COLLECTIONS = [
  "event",
  "exhibition",
  "event-category",
  "page",
  "archive",
  "publication",
  "venue",
];

export const PAGES = [...SINGLETONS, ...COLLECTIONS];

export const OPTIONS = [
  {
    title: "Site Settings",
    name: "settings",
  },
  {
    title: "Contact",
    name: "contact",
  },
  {
    title: "Menu",
    name: "nav",
  },
  {
    title: "Interim Menu",
    name: "interim-nav",
  },
  {
    title: "Newsletter",
    name: "newsletter",
  },
  {
    title: "Cookie",
    name: "cookie",
  },
  {
    title: "Footer",
    name: "footer",
  },
];

// Document types which:
// - cannot be created in the 'new document' menu
// - cannot be duplicated, unpublished or deleted
export const LOCKED_DOCUMENT_TYPES = [
  ...SINGLETONS,
  ...OPTIONS.map((o) => o.name),
  "media.tag",
  "translation.metadata",
];

// References to include in 'internal' links
export const PAGE_REFERENCES = PAGES.map((type) => ({
  type,
}));

export const PREVIEWABLE_DOCUMENT_TYPES = [
  "home",
  "page",
  "event",
  "exhibition",
  "archive",
];
